import React from 'react';
import classes from './Telephone.module.scss';
import PInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {useTranslation} from 'react-i18next';

const Telephone = (props) => {
    const {t} = useTranslation();
    const name = 'telephone';
    function convertPhoneNumber(telNumber) {
        props.change(telNumber, 'telephone');
    }

    return (
        <div className={classes.wrapper}>
            <label htmlFor={name} className={classes.inputLabel}>
                <div className={classes.label}>
                    {t('translations:form.' + name + '.name')} {(props.required ? '*' : '')}
                </div>

                <PInput
                    country='de'
                    autoFormat={false}
                    value={props.value}
                    onChange={convertPhoneNumber}
                    onlyCountries={['ua', 'de', 'pl', 'ro', 'bg']}
                />
            </label>
        </div>
    );
}

export default Telephone;

